import React from "react";
import { Chart } from "react-google-charts";

export const CustomChart = ({
  className = "stats-chart",
  width = "470px",
  height = "357px",
  chartType = "AreaChart",
  options = {
    series: [{ color: "#25BA00" }],
    curveType: "function",
    intervals: { color: "#ffffff" },
    pointSize: window.innerWidth < 700 ? 22.5 : 7,
    hAxis: {
      format: "dd.MM.yy",
    },
  },
  rootProps = { "data-testid": "2" },
  data,
}) => {
  return (
    <Chart
      className={className}
      width={width}
      height={height}
      chartType={chartType}
      data={[
        [
          { type: "date", label: "Дата", id: "Date" },
          { type: "number", label: "", id: "Won/Loss" },
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ].concat(data || [])}
      options={options}
      rootProps={rootProps}
    />
  );
};
